import { Box, Stack, Title, Flex, Image } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useLoginUser } from 'redux/selectors/useLoginUser';
import loading from 'assets/report/loading.gif';
import { GradientButton } from 'components/GradientButton';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { API } from 'services';

export function InvoicesPage() {
  const { loginUser } = useLoginUser();
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // prevent horizontal scroll back and forward navigation
    // because our datatable require horizontal scroll alots
    const cached = document.body.style.overscrollBehaviorX;
    document.body.style.overscrollBehaviorX = 'none';
    return () => {
      document.body.style.overscrollBehaviorX = cached;
    };
  }, []);

  const report = useQuery([], () => {
    setLoading(true); // Set loading state to true before making the API request
    return API.getInvoiceListing({})
      .then((res) => {
        setLoading(false); // Set loading state to false when the request is complete
        return res.data;
      })
      .catch((error) => {
        setLoading(false); // Make sure to set loading state to false in case of an error
        throw error;
      });
  });

  return (
    <Stack px={{ base: 0, md: 30 }}>
      {isLoading && (
        <Box className={'loading_box'}>
          <Image src={loading} width="100"></Image>
        </Box>
      )}
      <Flex className={'flex_wrap'}>
        <Box className={'w100 md_w100'}>
          <Box bg="#FFF" p={20}>
            <Box mb={20} className="flex flex_direction_row flex_align_center flex_justify_between">
              <Title order={2}>Invoices</Title>
              <GradientButton px={40} onClick={() => navigate('/invoices/generate')}>
                Generate Invoice
              </GradientButton>
            </Box>
            <Box my={30} style={{ overflowX: 'auto' }}>
              <table className="reportTable">
                <thead>
                  <tr>
                    <th style={{ background: '#f2f4fd' }}>Invoice Date</th>
                    <th style={{ background: '#f2f4fd' }}>Invoice No.</th>
                    <th style={{ background: '#f5fdfc' }}>No. of Classes</th>
                    <th style={{ background: '#f5fdfc' }}>Total Amount</th>
                    <th style={{ background: '#fefaf3' }}>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {report.data &&
                    report.data.trainer_invoices &&
                    report.data.trainer_invoices.length > 0 &&
                    report.data.trainer_invoices.map((item: any) => (
                      <tr>
                        <td>{item['trainer_invoice_date']}</td>
                        <td>
                          <a
                            // href={'/course?course='+data.course_id+'&course_run='+data.courserun_id}
                            href={'/invoices/details?invoice_id=' + item.id}
                            style={{ cursor: 'pointer' }}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item['trainer_invoice_no']}
                          </a>
                        </td>
                        <td>{item['invoice_courserun'].length}</td>
                        <td>{'$' + parseFloat(item['total_amount']).toFixed(2)}</td>
                        <td>
                          {item['status'] == 'Submitted' && (
                            <span style={{ color: '#ffbd43', fontWeight: '600' }}>
                              {'Submitted'}
                            </span>
                          )}
                          {item['status'] == 'Rejected' && (
                            <>
                              <span style={{ color: '#fa5252', fontWeight: '600' }}>
                                {'Rejected'}
                              </span>
                              <br></br>
                              <span>Remarks: {item['status_remarks'] ?? '-'}</span>
                            </>
                          )}
                          {item['status'] == 'Approved' && (
                            <span style={{ color: '#00ab84', fontWeight: '600' }}>
                              {'Approved'}
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                  {report.data &&
                    report.data.trainer_invoices &&
                    report.data.trainer_invoices.length <= 0 && (
                      <tr>
                        <td colSpan={5}>{'No Invoice Available'}</td>
                      </tr>
                    )}
                </tbody>
              </table>
            </Box>
          </Box>
        </Box>
      </Flex>
    </Stack>
  );
}
