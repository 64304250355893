// import React from 'react';
import {
  Anchor,
  Box,
  Button,
  Flex,
  Image,
  Title,
  Text
} from '@mantine/core';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { ReactComponent as BookSvg } from 'assets/inner/icon_book.svg';
import { ReactComponent as LocateSvg } from 'assets/inner/icon_locate.svg';
import { ReactComponent as CalSvg } from 'assets/inner/icon_cal.svg';
import { ReactComponent as ClockSvg } from 'assets/inner/icon_clock.svg';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Select } from '@mantine/core';
import { API } from 'services';
import { useState } from 'react';
import ppImage from 'assets/inner/pp.png';
import googleImage from 'assets/inner/google.png';
import { Outlet, useNavigate } from 'react-router-dom';
import moment from 'moment';
// import '@fullcalendar/core/main.css';
// import '@fullcalendar/daygrid/main.css';

export function Calendar({ loginUser, setLoading }:any) {

  const handleSyncGoogleCal = () => {
    // const { data: userData, refetch: refetchUser } = useQuery('loginUser', fetchLoginUser);

    setLoading(true);
    API.syncGoogleCal()
      .then((res) => {
        // console.log(res.data);        
        setLoading(false);
        window.location.reload();
      })
      .catch((error) => {
        console.error('Error syncing Google Calendar:', error);
      });
  };

  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null)
  const [date_start, setDateStart] = useState(moment().subtract(1, 'months').format('YYYY-MM-DD'))
  const [date_end, setDateEnd] = useState(moment().add(1, 'months').format('YYYY-MM-DD'))
  const [course_detail, setCourseDetail] = useState<any>(<Box></Box>);

  const course = useQuery(['course'], () => { 
    return API.getCourse()
      .then((res) => res.data); 
  });

  const convertCourseRunToEvents = (courseRunData:any) => {
    return courseRunData.map((item:any) => ({
      title: item.courserun_modeoftraining === "Face-to-Face Classroom"
      ? item.traininglocation_title + ' - ' + item.course_title
      : 'Online Class - ' + item.course_title,
      start: item.courserun_course_from,
      end: item.courserun_course_to+' 11:59:59',
      color: item.course_color,
      classNames: ['calendar_event'],
      // url: "/course?course="+item.course_id+"&course_run="+item.courserun_id
      extendedProps: {
        courseId: item.course_id,
        courseRunId: item.courserun_id,
      },
    }));
  };

  const handleClick = async (courseId:any, courserunId:any) => {
    try {
      // Call your API here using async/await syntax
      const response = await API.getCourseDetail({
        course_id: courseId,
        courserun_id: courserunId,
      });
      
      const data = await response.data; // Assuming your API response contains data
      setCourseDetail(
        <Box className={'dashboard_course_detail'}>
          <Flex>
            {data.user_profile_img ? (
              <Image
                src={import.meta.env.VITE_API_URL +'/'+ data.user_profile_img} width="80" className="profile_image"
              >
              </Image>
            ) : (
              <Image
                src={ppImage} width="80" className="profile_image"
              >
              </Image>
            )}
            <Box px={20} >
              <Title order={2}>
                <Text display={'inline'} color="#8c4799" ml={4}>
                  { data.user_name }
                </Text>
              </Title>
              <Text size={'lg'}>Student Services Executive</Text>
            </Box>
          </Flex>
          <Flex className={'flex_align_center'}><BookSvg style={{marginRight: "5px"}}/><Text>{data.course_title}</Text></Flex>
          <Flex className={'flex_align_center'}><LocateSvg style={{marginRight: "5px"}}/>
            <Text
              dangerouslySetInnerHTML={{
                __html:
                  data.courserun_modeoftraining === "Face-to-Face Classroom"
                    ? "" +
                      data.traininglocation_title +
                      "<br>#" +
                      data.venue_floor +
                      "-" +
                      data.venue_unit +
                      ", " +
                      data.venue_bulding +
                      " <br>Singapore " +
                      data.venue_postalcode
                    : "Online Class",
              }}
            ></Text>
          </Flex>
          <Flex className={'flex_align_center'}><CalSvg style={{marginRight: "5px"}}/>
            <Text>{ new Date(data.courserun_course_from).toLocaleDateString('en-GB', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
              weekday: 'short',
            }) } - 
            { new Date(data.courserun_course_to).toLocaleDateString('en-GB', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
              weekday: 'short',
            }) } </Text>
          </Flex>
          <Flex className={'flex_align_center'}><ClockSvg style={{marginRight: "5px"}}/><Text dangerouslySetInnerHTML={{ __html: data.session }}></Text></Flex>
          {/* <Anchor mx={10} href={"/course?course="+data.course_id + "&course_run=" + data.courserun_id}>View Detail</Anchor> */}
          <Flex className={'flex_align_center'}>
            <Button onClick={() => navigate('/course?course='+data.course_id+'&course_run='+data.courserun_id)}>View Detail</Button>
          </Flex>
        </Box>
      );
      
      // Handle your API response data here
    } catch (error) {
      // Handle errors if the API call fails
    }
  };
  
  const convertToCourseList = (courseRunData:any) => {
    return courseRunData.map((item:any, index:any) => (
      <Box key={index} bg={item.course_color} p={5} m={5} onClick={() => handleClick(item.course_id, item.courserun_id)} style={{cursor:"pointer"}}>
        <Text color="#fff">
        {item.courserun_modeoftraining === "Face-to-Face Classroom"
        ? item.traininglocation_title + " - " + item.course_title
        : "Online Class - " + item.course_title}
        </Text>
      </Box>
    ));
  };
  
  const courseRun = useQuery(['courserun', { 
      course_id: selectedOption ? selectedOption : 0, 
      date_start: date_start ? date_start : moment().subtract(1, 'months').format('YYYY-MM-DD'), 
      date_end: date_end ? date_end : moment().add(1, 'months').format('YYYY-MM-DD')
    }], () => {
    setCourseDetail("");
    return API.getCourseRun({ 
      course_id: selectedOption ? selectedOption : 0, 
      date_start: date_start ? date_start : moment().subtract(1, 'months').format('YYYY-MM-DD'), 
      date_end: date_end ? date_end : moment().add(1, 'months').format('YYYY-MM-DD') 
    })
      .then((res) => res.data);
  });

  const events = courseRun.data ? convertCourseRunToEvents(courseRun.data) : [];
  const course_lists = courseRun.data ? convertToCourseList(courseRun.data) : [];

  const handleSelectChange = (selectedOption:any) => {
    setSelectedOption(selectedOption);

    // Call your API here using the selected value
    if (selectedOption) {
      courseRun.refetch({ course_id:selectedOption }as any);
    }
  };

  const redirection = (event:any) => {
    const { courseId, courseRunId } = event.event.extendedProps;
    navigate('/course?course='+courseId+'&course_run='+courseRunId);
  };

  const handleDatesSet = (info:any) => {
    const inputDate = new Date(info.start);
    setDateStart(formatDate(inputDate));
    const inputEndDate = new Date(info.end);
    setDateEnd(formatDate(inputEndDate));
    
    courseRun.refetch({ course_id: selectedOption, date_start: date_start, date_end: date_end } as any);
  };

  const formatDate = (inputDate:any) => {
    return moment(inputDate).format('YYYY-MM-DD');
  };

  return (
    <Flex className={'flex_wrap'}>
        <Box p={10} className={'w30 sm_w100 sm_order_2'}>
          <Box bg="#fff" p={20}>
            <Title order={2} mb={'lg'}>
              Course List
            </Title>
            <hr></hr>
            <Box>
              {course_lists}
            </Box>
          </Box>
          
          <Box bg="#fff" p={20} mt={20}>
            <Title order={2} mb={'lg'}>
              Course Detail
            </Title>
            <hr></hr>
            <Box>
              {course_detail}
            </Box>
          </Box>
        </Box>
        
        <Box p={10} className={'w70 sm_w100'}>
          <Box bg="#fff" p={20}>
            <Flex style={{position: 'relative'}}>
              <Title order={2} mb={'lg'}>
                Course Run
              </Title>
              
              {!loginUser.sync_calendar && (
                <Button variant="outline" color="dark" radius="lg" size="xs" style={{position: 'absolute', right: '0'}} onClick={handleSyncGoogleCal}>
                  <Image src={googleImage} mx={5} width="20" className="profile_image"></Image> Sync Google Cal
                </Button>
              )}

            </Flex>

            <hr></hr>

            <Box>
              <div>
                <Select
                  my={20}
                  className="w50 sm_w100"
                  label="Course Title"
                  placeholder="All"
                  data={course.data ? course.data : []}
                  onChange={handleSelectChange}
                  defaultValue="React"
                  clearable
                />

                <FullCalendar
                  plugins={[dayGridPlugin]}
                  initialView="dayGridMonth"
                  headerToolbar={{
                    start:"",
                    center: "title",
                    end: "prev,next today"
                  }}
                  eventClick={redirection}
                  events={events}
                  datesSet={handleDatesSet}
                />
              </div>
            </Box>
          </Box>
        </Box>
      </Flex>
  );
}