import {
  Box,
  Stack,
  Text,
  Title,
  Flex,
} from '@mantine/core';
import DatePicker from "react-datepicker";
import { API } from 'services';
import { useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { DataTable } from 'components/DataTable';
import moment from 'moment';
import Swal from 'sweetalert2';

import { ReactComponent as CalSvg } from 'assets/inner/icon_cal.svg';
import { ReactComponent as BookSvg } from 'assets/inner/icon_book.svg';
import { ReactComponent as ClockSvg } from 'assets/inner/icon_clock.svg';
import { ReactComponent as LocateSvg } from 'assets/inner/icon_locate.svg';
import { ReactComponent as NameSvg } from 'assets/inner/name.svg';
import loading from 'assets/report/loading.gif';
// import { AcknowledgeModal } from '../Course/acknowledgeModal';

const renderEventContent = (eventInfo:any) => {
  const { customHtml } = eventInfo.event.extendedProps;
  return (
    <div dangerouslySetInnerHTML={{ __html: customHtml }}></div>
  );
};

const formatSessionDateRange = (start_date:any, end_date:any) => {
  // Parse start and end dates using moment
  const startDate = moment(start_date);
  const endDate = moment(end_date);

  // Format start date like "06 May, Mon"
  const formattedStartDate = startDate.format('DD MMM, ddd');

  // Format end date like "08 May, Web"
  const formattedEndDate = endDate.format('DD MMM, ddd');

  // Combine formatted dates with a dash
  const formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`;

  return formattedDateRange;
};

export function CourseRunRecordPage(props: any) {
  const [date_start, setDateStart] = useState(moment().subtract(1, 'months').format('YYYY-MM-DD'));
  const [date_end, setDateEnd] = useState(moment().add(1, 'months').format('YYYY-MM-DD'));
  const [course_detail, setCourseDetail] = useState<any>(<Box></Box>);

  const [selectedLocation, setSelectedLocation] = useState(0);
  const [selectedCourse, setSelectedCourse] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const filter_date = new Date();
  const year = filter_date.getFullYear();
  const month = filter_date.getMonth();
  const thisMonthFirstDay = new Date(year, month - 1, 1);
  const currentStartDayOfMonth = new Date(thisMonthFirstDay.getFullYear(), thisMonthFirstDay.getMonth(), 1);
  const nextMonthFirstDay = new Date(year, month + 6, 1);
  const currentEndDayOfMonth = new Date(nextMonthFirstDay.getFullYear(), nextMonthFirstDay.getMonth(), 0);
  
  const [filter_date_start, setFilterDateStart] = useState(currentStartDayOfMonth);
  const [filter_date_end, setFilterDateEnd] = useState(currentEndDayOfMonth);
  
  const course_histories = useQuery(['course_histories', filter_date_start, filter_date_end,], () => { 
    return API.getSubmittedCourseRecord({
      filter_date_start: moment(filter_date_start).format("DD-MM-Y"),
      filter_date_end: moment(filter_date_end).format("DD-MM-Y"),
    }).then((res) => {
      return res.data;
    }); 
  });

  const handleWithdraw = async (id:any) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to withdraw your interest for this course?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, submit it!',
      cancelButtonText: 'No, cancel!',
    });
  
    // If user confirmed, proceed with API call
    if (result.isConfirmed) {
      API.withdrawInterest({
        id: id,
      }).then((response) => {
        setLoading(false);
        if(response.data.status){
          Swal.fire({
            title: 'Submitted!',
            text: 'Your withdrawal has been submitted.',
            icon: 'success',
            confirmButtonText: 'OK',
          });
          course_histories.refetch();
        }else{
          Swal.fire({
            title: 'Fail!',
            text: response.data.message,
            icon: 'warning',
            confirmButtonText: 'OK',
          });
        }
      }).catch((error) => {
        Swal.fire({
          title: 'Error!',
          text: 'There was an error submitting your withdrawal. Please try again.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      });
    }
  };

  return (
    <Stack px={{ base: 0, md: 30 }}>
      
      <Box className="report_date">
        <Flex>
          <Box mx={10}>
            <Text>Start Date</Text>
            <DatePicker 
              selected={filter_date_start} 
              onChange={(date:any) => setFilterDateStart(date)} 
              dateFormat="dd-MM-yyyy"
            />
          </Box>
          <Box>
            <Text>End Date</Text>
            <DatePicker 
              selected={filter_date_end} 
              onChange={(date:any) => setFilterDateEnd(date)} 
              dateFormat="dd-MM-yyyy"
            />
          </Box>
        </Flex>
      </Box>

      {course_histories &&
        <DataTable
          className={'learner_table'}
          records={course_histories.data ? course_histories.data.courses : []}
          columns={[
            { accessor: 'sequence_number', sortable: false, 'title': '#' },
            { accessor: 'course_title', sortable: false, 'title': 'Course Title', render: (value:any)=>{
              return <div dangerouslySetInnerHTML={{ __html: value.course_title }}></div>
            } },
            { accessor: 'traininglocation_title_string', sortable: false, 'title': 'Location', render: (value:any)=>{
              return <div dangerouslySetInnerHTML={{ __html: value.traininglocation_title_string }}></div>
            } },
            { accessor: 'csession_date_string', sortable: false, 'title': 'Date', render: (value:any)=>{
              return <div dangerouslySetInnerHTML={{ __html: value.csession_date_string }}></div>
            } },
            { accessor: 'session_string', sortable: false, 'title': 'Time', render: (value:any)=>{
              return <div dangerouslySetInnerHTML={{ __html: value.session_string }}></div>
            } },
            { accessor: 'status_string', sortable: false, 'title': 'Status', render: (value:any)=>{
              return <div dangerouslySetInnerHTML={{ __html: value.status_string }}></div>
            } },
            { accessor: 'status_string', sortable: false, 'title': 'Action', render: (value:any)=>{
              return value.status_string === "<span style='color:blue'>Pending</span>" 
                  ? <button className='interest_withdraw_button' onClick={() => handleWithdraw(value.id)}>Withdraw</button> 
                  : null;
            } },
          ]}
        />
      }
      
    </Stack>
  );
}
