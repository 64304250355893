import { Layout } from 'components/layout/Layout';
import { DashboardPage } from 'pages/DashboardPage/DashboardPage';
import { LoginPage } from 'pages/Login/LoginPage';
import { ProfilePage } from 'pages/Profile/ProfilePage';
import { ReportPage } from 'pages/Report/ReportPage';
import { CoursePage } from 'pages/Course/CoursePage';
import { CourseRunPage } from 'pages/CourseRun/CourseRunPage';
import { CourseRunRecordPage } from 'pages/CourseRun/CourseRunRecordPage';
import { EvaluationPage } from 'pages/Evaluation/EvaluationPage';
import { InvoicesPage } from 'pages/Invoices/InvoicesPage';
import { InvoicesDetails } from 'pages/Invoices/InvoicesDetails';
import { InvoicesGenerate } from 'pages/Invoices/InvoicesGenerate';

import { Navigate, RouteObject, createBrowserRouter } from 'react-router-dom';
import { useLoginUser } from 'redux/selectors/useLoginUser';

import { ErrorBoundary } from '@sentry/react';
import * as Sentry from '@sentry/react';

// const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
// const router = sentryCreateBrowserRouter(route);

const RedirectBasedOnUserType = () => {
  const { loginUser } = useLoginUser();

  // Check if the user is a corporate user
  const isCorporateUser = loginUser?.is_corporate === 1;

  // Conditionally navigate based on the user type
  return isCorporateUser ? <Navigate to="/profile" /> : <Navigate to="/dashboard" />;
};

const DashboardGuard = ({ children }: { children: JSX.Element }) => {
  const { loginUser } = useLoginUser();

  // Add your condition here to determine if the user is allowed to access the page
  const isCorporateUser = loginUser?.is_corporate === 1;

  if (isCorporateUser) {
    return <Navigate to="/profile" />; // Redirect to profile if the user is a corporate user
  }

  return children; // Allow access to the dashboard if not corporate
};

const InvoiceGuard = ({ children }: { children: JSX.Element }) => {
  const { loginUser } = useLoginUser();

  // Add your condition here to determine if the user is allowed to access the page
  let isCorporateUser = true;
  if (loginUser?.is_corporate === 1) {
    isCorporateUser = false;
  } else {
    isCorporateUser = loginUser?.trainer_corporate_id !== null;
  }

  if (isCorporateUser) {
    return <Navigate to="/profile" />; // Redirect to profile if the user is a corporate user
  }

  return children; // Allow access to the dashboard if not corporate
};

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
const route: RouteObject[] = [
  {
    errorElement: <ErrorBoundary fallback={<div>Something went wrong</div>} />,
    children: [
      {
        path: '/login',
        element: <LoginPage />,
      },
      {
        path: '/evaluation',
        element: <EvaluationPage />,
      },
      {
        path: '/',
        element: <Layout />,
        children: [
          {
            path: '*',
            element: <RedirectBasedOnUserType />,
          },
          {
            path: '/',
            element: <RedirectBasedOnUserType />,
          },
          {
            path: 'dashboard',
            element: (
              <DashboardGuard>
                <DashboardPage />
              </DashboardGuard>
            ),
          },
          {
            path: 'profile',
            element: <ProfilePage />,
          },
          {
            path: 'course',
            element: <CoursePage />,
          },
          {
            path: 'report',
            element: <ReportPage />,
          },
          {
            path: 'courserun',
            element: (
              <DashboardGuard>
                <CourseRunPage />
              </DashboardGuard>
            ),
          },
          {
            path: 'courserun_record',
            element: (
              <DashboardGuard>
                <CourseRunRecordPage />
              </DashboardGuard>
            ),
          },
          {
            path: 'invoices',
            element: (
              <InvoiceGuard>
                <InvoicesPage />
              </InvoiceGuard>
            ),
          },
          {
            path: 'invoices/details',
            element: (
              <InvoiceGuard>
                <InvoicesDetails />
              </InvoiceGuard>
            ),
          },
          {
            path: 'invoices/generate',
            element: (
              <InvoiceGuard>
                <InvoicesGenerate />
              </InvoiceGuard>
            ),
          },
        ],
      },
    ],
  },
];

const router = sentryCreateBrowserRouter(route);

export default router;
