import {
  Box,
  Stack,
  Title,
  Flex,
  Image,
  Text,
  TextInput,
  Group,
  Button,
  Checkbox,
  SimpleGrid,
  Grid,
  Col,
  Switch,
  FileInput,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { useLoginUser } from 'redux/selectors/useLoginUser';
import loading from 'assets/report/loading.gif';
import { GradientButton } from 'components/GradientButton';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { API } from 'services';
import { z } from 'zod';
import { useForm, zodResolver } from '@mantine/form';
import {
  IconDeviceFloppy,
  IconGift,
  IconLockAccess,
  IconMail,
  IconPhone,
  IconRefresh,
  IconUser,
} from '@tabler/icons-react';
import { modals } from '@mantine/modals';
import { useDispatch } from 'react-redux';
import { fetchUserInfoThunk } from 'redux/reducers/auth';
import { notifications } from 'notifications';
import { getErrorMessage } from 'services/getErrorMessage';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const schema = z.object({
  name: z
    .string()
    .max(255, { message: 'Name is too long' })
    .nonempty({ message: 'Name is required' }),
  // email: z.string().email({ message: 'Invalid email' }),
  mobile: z
    .string()
    .max(255, { message: 'Mobile is too long' })
    .nonempty({ message: 'Mobile is required' }),
});

type FormValue = z.infer<typeof schema>;

export function InvoicesGenerate() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginUser } = useLoginUser();
  const [isLoading, setLoading] = useState(false);
  const [totalSelectedClasses, setTotalSelectedClasses] = useState(0);
  const [totalInvoiceAmount, setTotalInvoiceAmount] = useState(0);
  const [totalTrainerRate, setTotalTrainerRate] = useState(0);
  const [totalTraqomIncentiveRate, setTotalTraqomIncentiveRate] = useState(0);
  const [totalBilingualIncentive, setTotalBilingualIncentive] = useState(0);
  const [totalLearnersIncentive, setTotalLearnersIncentive] = useState(0);
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [isPaynow, setIsPaynow] = useState(false);
  const [allowances, setAllowances] = useState<number[]>([]);

  const form = useForm({
    initialValues: {
      invoice_no: '',
      bank_name: '',
      bank_account_number: '',
      bank_recipient_name: '',
      paynow_uen: '',
      paynow_mobile_no: '',
      paynow_recipient_name: '',
      selected_courseruns: [] as string[],
      allowances:
        allowances.length > 0
          ? [{ allowance_title: '', allowance_amount: '', allowance_image: null }]
          : [],
    },
    validate: {
      invoice_no: (value) => (value.trim().length < 1 ? 'Please enter Invoice No.' : null),
      bank_name: (value) =>
        !isPaynow && value.trim().length < 1 ? 'Please enter Bank Name' : null,
      bank_account_number: (value) =>
        !isPaynow && value.trim().length < 1 ? 'Please enter Bank Account Number' : null,
      bank_recipient_name: (value) =>
        !isPaynow && value.trim().length < 1 ? 'Please enter Bank Recipient Name' : null,
      paynow_uen: (value, values) =>
        isPaynow && value.trim().length < 1 && values.paynow_mobile_no.trim().length < 1
          ? 'Please enter either Paynow UEN or Paynow Mobile No.'
          : null,
      paynow_mobile_no: (value, values) =>
        isPaynow && value.trim().length < 1 && values.paynow_uen.trim().length < 1
          ? 'Please enter either Paynow UEN or Paynow Mobile No.'
          : null,
      paynow_recipient_name: (value) =>
        isPaynow && value.trim().length < 1 ? 'Please enter Paynow Recipient Name' : null,
      selected_courseruns: (value) =>
        value.length > 0 ? null : 'Please select at least one class',
      allowances:
        allowances.length > 0
          ? {
              // Validate each field in the allowances array
              allowance_title: (value) =>
                allowances.length > 0 && value.trim().length < 1
                  ? 'Please enter Allowance Title'
                  : null,
              allowance_amount: (value) =>
                allowances.length > 0 && value.trim().length < 1
                  ? 'Please enter Allowance Amount'
                  : null,
              allowance_image: (value) => null,
            }
          : {},
    },
  });

  useEffect(() => {
    // prevent horizontal scroll back and forward navigation
    // because our datatable require horizontal scroll alots
    const cached = document.body.style.overscrollBehaviorX;
    document.body.style.overscrollBehaviorX = 'none';
    return () => {
      document.body.style.overscrollBehaviorX = cached;
    };
  }, []);

  const available_courserun = useQuery(['availablecourserun'], () => {
    setLoading(true); // Set loading state to true before making the API request
    return API.getInvoiceAvailableCourserun({})
      .then((res) => {
        setLoading(false); // Set loading state to false when the request is complete
        return res.data;
      })
      .catch((error) => {
        setLoading(false); // Make sure to set loading state to false in case of an error
        throw error;
      });
  });

  const handleSubmitSection1 = (data: any) => {
    if (available_courserun.data && available_courserun.data.length <= 0) {
      notifications.error({
        title: 'Invoice submission error',
        message: 'There is no completed class',
      });
    } else {
      let selected_courseruns = data.selected_courseruns;
      let invoice_no = data.invoice_no;
      let invoice_date = invoiceDate && invoiceDate !== '' ? formatDate(invoiceDate) : '';
      let invoice_total_amount = totalInvoiceAmount;
      let total_trainer_fee = totalTrainerRate;
      let total_traqom_incentive = totalTraqomIncentiveRate;
      let total_bilingual_incentive = totalBilingualIncentive;
      let total_learners_incentive = totalLearnersIncentive;
      let is_paynow = isPaynow;
      let bank_account_number = data.bank_account_number;
      let bank_name = data.bank_name;
      let bank_recipient_name = data.bank_recipient_name;
      let paynow_mobile_no = data.paynow_mobile_no;
      let paynow_recipient_name = data.paynow_recipient_name;
      let paynow_uen = data.paynow_uen;
      let allowances = data.allowances;

      setLoading(true);

      return API.submitInvoice({
        selected_courseruns: selected_courseruns,
        invoice_no: invoice_no,
        invoice_date: invoice_date,
        invoice_total_amount: invoice_total_amount,
        total_trainer_fee: total_trainer_fee,
        total_traqom_incentive: total_traqom_incentive,
        total_bilingual_incentive: total_bilingual_incentive,
        total_learners_incentive: total_learners_incentive,
        is_paynow: is_paynow,
        bank_account_number: bank_account_number,
        bank_name: bank_name,
        bank_recipient_name: bank_recipient_name,
        paynow_mobile_no: paynow_mobile_no,
        paynow_recipient_name: paynow_recipient_name,
        paynow_uen: paynow_uen,
        allowances: allowances,
      } as any).then((response) => {
        if (response.data.status) {
          // success
          setLoading(false);
          notifications.success({
            title: response.data.title,
            message: '',
          });
          navigate('/invoices', { replace: true });
        } else {
          setLoading(false);
          notifications.error({
            title: response.data.title,
            message: response.data.message,
          });
        }
      });
    }
  };

  const handleCheckboxChange = (selected_courseruns: any) => {
    let total_trainer_rate = 0;
    let total_traqom_rate = 0;
    let total_bilingual_rate = 0;
    let total_learners_rate = 0;
    let total_rate = 0;

    if (selected_courseruns.length > 0) {
      selected_courseruns.forEach((id: string) => {
        const selectedCourserun = available_courserun.data.find(
          (item: any) => item.courserun_id === parseInt(id)
        );
        if (selectedCourserun) {
          total_trainer_rate += selectedCourserun.totalTrainerRate;
          total_traqom_rate += selectedCourserun.totalTraqomIncentiveRate;
          total_bilingual_rate += selectedCourserun.totalBilingualIncentive;
          total_learners_rate += selectedCourserun.totalLearnersIncentive;
          let total =
            selectedCourserun.totalTrainerRate +
            selectedCourserun.totalTraqomIncentiveRate +
            selectedCourserun.totalBilingualIncentive +
            selectedCourserun.totalLearnersIncentive;
          total_rate += total;
        }
      });
    }
    setTotalInvoiceAmount(total_rate);
    setTotalTrainerRate(total_trainer_rate);
    setTotalTraqomIncentiveRate(total_traqom_rate);
    setTotalBilingualIncentive(total_bilingual_rate);
    setTotalLearnersIncentive(total_learners_rate);
    setTotalSelectedClasses(selected_courseruns.length);
  };

  // Function to add new allowance form
  const addAllowance = () => {
    setAllowances((prev) => [...prev, prev.length]); // Add a new item to the list
    form.insertListItem('allowances', {
      allowance_title: '',
      allowance_amount: '',
      allowance_image: null,
    });
  };

  // Function to remove an allowance
  const removeAllowance = (index: any) => {
    setAllowances((prev) => prev.filter((_, i) => i !== index)); // Remove from state
    form.removeListItem('allowances', index); // Remove from form state
  };

  const formatDate = (date: any) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  return (
    <Stack px={{ base: 0, md: 30 }}>
      {isLoading && (
        <Box className={'loading_box'}>
          <Image src={loading} width="100"></Image>
        </Box>
      )}
      <Flex className={'flex_wrap'}>
        <Box className={'w100 md_w100'}>
          <Box bg="#FFF" p={20}>
            <Box mb={20} className="flex flex_direction_row flex_align_center flex_justify_between">
              <Title order={2}>Generate Invoice</Title>
            </Box>
            <Box>
              <form onSubmit={form.onSubmit(handleSubmitSection1)}>
                <Flex
                  w="100%"
                  p={20}
                  my={10}
                  direction="row"
                  wrap="wrap"
                  style={{ backgroundColor: '#f2fbf9' }}
                >
                  <Title order={4} my={10} w="100%">
                    Select Completed Classes to Invoice <span style={{ color: '#fa5252' }}>*</span>
                  </Title>
                  <Box mt={20} w="100%">
                    <Checkbox.Group
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      name="selected_courseruns"
                      label=""
                      onChange={(selectedValues) => {
                        // Update form state
                        form.setFieldValue('selected_courseruns', selectedValues);
                        handleCheckboxChange(selectedValues);
                      }}
                      // {...form.getInputProps('selected_courseruns')}
                      withAsterisk
                    >
                      <Grid>
                        {available_courserun.data &&
                          available_courserun.data.length > 0 &&
                          available_courserun.data.map(function (item: any, i: any) {
                            return (
                              <Col key={item.courserun_id} span={12} md={12}>
                                <Checkbox
                                  key={item.courserun_id}
                                  sx={{
                                    width: '100%',
                                    '.mantine-Checkbox-labelWrapper': {
                                      display: 'flex',
                                      flex: '1 !important',
                                    },
                                    '.mantine-Checkbox-body': {
                                      display: 'flex',
                                      alignItems: 'center',
                                    },
                                  }}
                                  value={item.courserun_id.toString()}
                                  label={
                                    <>
                                      <b>
                                        {item.course_title} ({item.course_short_title})
                                      </b>
                                      <br />
                                      <Box className="flex flex_direction_row flex_align_center">
                                        <Col span={6} md={6}>
                                          <Box>
                                            {loginUser.is_corporate == 1 && (
                                              <>
                                                Trainer: {item.trainer_name}
                                                <br />
                                              </>
                                            )}
                                            {item.courserun_course_from} -{' '}
                                            {item.courserun_course_to}
                                            <br />
                                            Course Run: {item.courserun_apicourserunid}
                                            <br />
                                            Location: {item.traininglocation_title}
                                            <br />
                                            Completed Learners: {item.total_learners}
                                          </Box>
                                        </Col>
                                        <Col span={6} md={6}>
                                          <Box>
                                            Trainer Fee: $
                                            {parseFloat(item.totalTrainerRate).toFixed(2)}
                                            <br />
                                            {item.totalTraqomIncentiveRate > 0 && (
                                              <>
                                                Traqom Incentive: $
                                                {parseFloat(item.totalTraqomIncentiveRate).toFixed(
                                                  2
                                                )}
                                                <br />
                                              </>
                                            )}
                                            {item.totalBilingualIncentive > 0 && (
                                              <>
                                                Bilingual Incentive: $
                                                {parseFloat(item.totalBilingualIncentive).toFixed(
                                                  2
                                                )}
                                                <br />
                                              </>
                                            )}
                                            {item.totalLearnersIncentive > 0 && (
                                              <>
                                                Learners Incentive: $
                                                {parseFloat(item.totalLearnersIncentive).toFixed(2)}
                                                <br />
                                              </>
                                            )}
                                            <b>
                                              Total: $
                                              {parseFloat(
                                                item.totalTrainerRate +
                                                  item.totalTraqomIncentiveRate +
                                                  item.totalBilingualIncentive +
                                                  item.totalLearnersIncentive
                                              ).toFixed(2)}
                                            </b>
                                          </Box>
                                        </Col>
                                      </Box>
                                    </>
                                  }
                                />
                                <hr style={{ borderTop: '1px solid', borderColor: '#ddd' }}></hr>
                              </Col>
                            );
                          })}
                        {available_courserun.data && available_courserun.data.length <= 0 && (
                          <Col span={12} md={12}>
                            <Box>
                              <Flex
                                w="100%"
                                p={20}
                                my={10}
                                direction="row"
                                wrap="wrap"
                                align={'center'}
                                justify="center"
                                style={{ backgroundColor: '#f2fbf9' }}
                              >
                                <Title order={4}>There is no completed class yet!</Title>
                              </Flex>
                            </Box>
                          </Col>
                        )}
                      </Grid>
                    </Checkbox.Group>
                    {isFormSubmit && totalSelectedClasses <= 0 && (
                      <p className="invoice_date_error" style={{ fontSize: 14 }}>
                        {'Please select at least one class'}
                      </p>
                    )}
                  </Box>
                </Flex>
                <hr style={{ borderTop: '1px solid', borderColor: '#ddd', marginTop: '30px' }}></hr>

                <Grid>
                  <Col span={12} sm={12} pb={0}>
                    <Title order={4} w="100%">
                      Invoice Information
                    </Title>
                  </Col>
                  <Col span={12} sm={6}>
                    <TextInput
                      w="100%"
                      label={
                        <>
                          Invoice No. <span style={{ color: '#fa5252' }}>*</span>
                        </>
                      }
                      placeholder="Invoice No."
                      {...form.getInputProps('invoice_no')}
                    />
                  </Col>
                  <Col span={12} sm={6}>
                    <Text className="invoice_date_title">
                      Invoice Date <span style={{ color: '#fa5252' }}>*</span>
                    </Text>
                    <DatePicker
                      selected={invoiceDate}
                      onChange={(date: any) => setInvoiceDate(date)}
                      dateFormat="dd-MM-yyyy"
                      wrapperClassName={`custom-datepicker-wrapper ${
                        isFormSubmit && invoiceDate === null ? 'error' : ''
                      }`}
                    />
                    {isFormSubmit && invoiceDate === null && (
                      <p className="invoice_date_error">{'Please enter Invoice Date'}</p>
                    )}
                  </Col>
                  <Col span={12} sm={6}>
                    <TextInput
                      disabled
                      w="100%"
                      label="Total Amount ($)"
                      value={totalInvoiceAmount}
                    />
                  </Col>
                  <Col span={12} sm={12} mt={10}>
                    <Switch
                      label="Is Paynow?"
                      checked={isPaynow}
                      onChange={(value) => setIsPaynow(value.target.checked)}
                    />
                  </Col>
                  {!isPaynow && (
                    <>
                      <Col span={12} sm={6}>
                        <TextInput
                          w="100%"
                          label={
                            <>
                              Bank Name <span style={{ color: '#fa5252' }}>*</span>
                            </>
                          }
                          placeholder="Bank Name"
                          {...form.getInputProps('bank_name')}
                        />
                      </Col>
                      <Col span={12} sm={6}>
                        <TextInput
                          w="100%"
                          label={
                            <>
                              Bank Account No. <span style={{ color: '#fa5252' }}>*</span>
                            </>
                          }
                          placeholder="Bank Account No."
                          {...form.getInputProps('bank_account_number')}
                        />
                      </Col>
                      <Col span={12} sm={6}>
                        <TextInput
                          w="100%"
                          label={
                            <>
                              Bank Recipient Name <span style={{ color: '#fa5252' }}>*</span>
                            </>
                          }
                          placeholder="Bank Recipient Name"
                          {...form.getInputProps('bank_recipient_name')}
                        />
                      </Col>
                    </>
                  )}
                  {isPaynow && (
                    <>
                      <Col span={12} sm={6}>
                        <TextInput
                          w="100%"
                          label={
                            <>
                              Paynow Mobile No. <span style={{ color: '#fa5252' }}>*</span>
                            </>
                          }
                          placeholder="Paynow Mobile No."
                          {...form.getInputProps('paynow_mobile_no')}
                        />
                      </Col>
                      <Col span={12} sm={6}>
                        <TextInput
                          w="100%"
                          label={
                            <>
                              Paynow UEN <span style={{ color: '#fa5252' }}>*</span>
                            </>
                          }
                          placeholder="Paynow UEN"
                          {...form.getInputProps('paynow_uen')}
                        />
                      </Col>
                      <Col span={12} sm={6}>
                        <TextInput
                          w="100%"
                          label={
                            <>
                              Paynow Recipient Name <span style={{ color: '#fa5252' }}>*</span>
                            </>
                          }
                          placeholder="Paynow Recipient Name"
                          {...form.getInputProps('paynow_recipient_name')}
                        />
                      </Col>
                    </>
                  )}
                </Grid>
                {(loginUser.trainer_grade == 'A' || loginUser.is_corporate == 1) && (
                  <>
                    <hr
                      style={{ borderTop: '1px solid', borderColor: '#ddd', marginTop: '20px' }}
                    ></hr>
                    <Grid>
                      <Col span={12} sm={12} pb={0}>
                        <Box
                          mb={0}
                          className="flex flex_direction_row flex_align_center flex_justify_between"
                        >
                          <Title order={4} w="100%">
                            Allowance
                          </Title>
                          <Button
                            m={4}
                            style={{ backgroundColor: '#00ab84' }}
                            onClick={addAllowance}
                            className={''}
                          >
                            Add Allowance
                          </Button>
                        </Box>
                      </Col>
                    </Grid>
                    <Box id={'allowance_inputs'}>
                      {allowances.map((allowance, index) => (
                        <Grid
                          key={index}
                          style={{ backgroundColor: '#CBEFE7', paddingBottom: 10, marginTop: 20 }}
                        >
                          <Col span={12} sm={6}>
                            <TextInput
                              w="100%"
                              label={
                                <>
                                  Allowance Title <span style={{ color: '#fa5252' }}>*</span>
                                </>
                              }
                              placeholder="Allowance Title"
                              {...form.getInputProps(`allowances.${index}.allowance_title`)} // Dynamic form input for bank_name
                            />
                          </Col>
                          <Col span={12} sm={6}>
                            <TextInput
                              w="100%"
                              label={
                                <>
                                  Allowance Amount ($) <span style={{ color: '#fa5252' }}>*</span>
                                </>
                              }
                              placeholder="Allowance Amount"
                              {...form.getInputProps(`allowances.${index}.allowance_amount`)} // Dynamic form input for bank_name
                              inputMode="decimal" // For allowing decimal values
                              pattern="^\d+(\.\d{0,2})?$" // Regular expression to allow up to 2 decimal places with at least one digit before the dot
                              onKeyPress={(e) => {
                                const inputValue = (e.target as HTMLInputElement).value + e.key;

                                // Allow only numbers, decimal points, and ensure only 2 decimal places with at least one digit before the dot
                                if (
                                  !/^\d+(\.\d{0,2})?$/.test(inputValue) &&
                                  e.key !== 'Backspace'
                                ) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </Col>
                          <Col span={12} sm={6}>
                            <FileInput
                              w="100%"
                              label="Upload Receipt"
                              placeholder="Upload a file"
                              {...form.getInputProps(`allowances.${index}.allowance_image`)} // Dynamic form input for the file
                              accept="image/png,image/jpeg" // Accept only images
                            />
                          </Col>
                          <Col
                            span={12}
                            sm={6}
                            className="flex flex_direction_row flex_align_center flex_justify_end"
                          >
                            <Button
                              m={4}
                              style={{ backgroundColor: '#fa5252' }}
                              onClick={() => removeAllowance(index)}
                              className={''}
                            >
                              Remove
                            </Button>
                          </Col>
                        </Grid>
                      ))}
                    </Box>
                  </>
                )}

                <hr style={{ borderTop: '1px solid', borderColor: '#ddd', marginTop: '20px' }}></hr>
                <Group mt={'lg'} position="right">
                  <GradientButton
                    type="submit"
                    mt="sm"
                    px={40}
                    onClick={() => setIsFormSubmit(true)}
                  >
                    Submit
                  </GradientButton>
                </Group>
              </form>
            </Box>
          </Box>
        </Box>
      </Flex>
    </Stack>
  );
}
