import { axios } from './axios';

export const login = (data: { email: string; password: string }) => {
  return axios.post('/trainer-api/login', data);
};

export const logout = () => {
  return axios.post('/trainer-api/logout');
};

export const getUser = () => {
  return axios.get('/trainer-api/get_user');
};

export const updateProfile = (data: { name: string; mobile: string }) => {
  return axios.post('/trainer-api/update_profile', data);
};

export const forgetpassword = (data: { email: string }) => {
  return axios.post('/trainer-api/request_forgetpassword_otp', data);
};

export const verifyPasswordOTP = (data: { email: string; otp: string }) => {
  return axios.post('/trainer-api/verify_forgetpassword_otp', data);
};

export const resetPassword = (data: {
  email?: string;
  otp: string;
  password: string;
  password_confirmation: string;
}) => {
  return axios.post('/trainer-api/reset_password', data);
};

export const resetPasswordFirstTime = (data: {
  password: string;
  password_confirmation: string;
}) => {
  return axios.post('/trainer-api/reset_password_firsttime', data);
};

export const getCourse = () => {
  return axios.get('/trainer-api/getAssignedCourse');
};

export const getCourseRun = (data: {
  course_id: string | number;
  date_start: string;
  date_end: string;
}) => {
  return axios.post('/trainer-api/getCourseRun', data);
};

export const getCourseLearner = (data: { courserun_id: string | null }) => {
  return axios.post('/trainer-api/getCourseLearner', data);
};

export const getLearnerAssessment = (data: { courserun_id: string | null }) => {
  return axios.post('/trainer-api/getLearnerAssessment', data);
};

export const getCourseDetail = (data: {
  course_id: string | null;
  courserun_id: string | null;
}) => {
  return axios.post('/trainer-api/getCourseDetail', data);
};

// export const getEvaluationData = () => {
//   return axios.post('/trainer-api/getEvaluationData');
// };
export const getAllCourse = () => {
  return axios.post('/trainer-api/getAllCourse');
};

export const getReportData = (data: {
  filter_date_start: string | null;
  filter_date_end: string | null;
  filter_trainer: string | null;
}) => {
  return axios.post('/trainer-api/getReportData', data);
};

export const evaluationSubmission = (data: {
  section1: [];
  section2: [];
  section3: [];
  section4: [];
  courseRunId: string;
}) => {
  return axios.post('/trainer-api/evaluationSubmission', data);
};

export const acknowledgeSubmission = (data: { courseRunId: string }) => {
  return axios.post('/trainer-api/acknowledgeSubmission', data);
};

export const trainerEvaluationSubmission = (data: { answers: []; courseRunId: string }) => {
  return axios.post('/trainer-api/trainerEvaluationSubmission', data);
};

export const zipTrainerDocument = (data: { file: [] }) => {
  return axios.post('/trainer-api/zipTrainerDocument', data);
};

export const syncGoogleCal = () => {
  return axios.post('/trainer-api/syncGoogleCal');
};

export const getUnassignCourse = (data: {
  date_start: string;
  date_end: string;
  location_id: number[] | string[];
  course_id: number[] | string[];
}) => {
  return axios.post('/trainer-api/getUnassignCourse', data);
};

export const getSubmittedCourseRecord = (data: {
  filter_date_start: string | null;
  filter_date_end: string | null;
}) => {
  return axios.post('/trainer-api/getTrainerSubmittedClasses', data);
};

export const submitInterest = (data: { courseRunId: string }) => {
  return axios.post('/trainer-api/submitInterest', data);
};

export const getCourseColor = () => {
  return axios.get('/trainer-api/getCourseColor');
};

export const getTrainingLocation = () => {
  return axios.get('/trainer-api/getTrainingLocation');
};

export const withdrawInterest = (data: { id: string | null }) => {
  return axios.post('/trainer-api/withdrawInterest', data);
};

// trainer invoices
export const getInvoiceListing = (data: {}) => {
  return axios.post('/trainer-api/getInvoiceListing', data);
};

export const getInvoiceAvailableCourserun = (data: {}) => {
  return axios.post('/trainer-api/getInvoiceAvailableCourserun', data);
};

export const getInvoiceDetails = (data: { invoice_id: string | null }) => {
  return axios.post('/trainer-api/getInvoiceDetails', data);
};

interface Allowance {
  allowance_title: string;
  allowance_amount: number;
  allowance_image: File; // Assuming this is a File object for uploads
}

export const submitInvoice = (data: {
  selected_courseruns: [];
  invoice_no: string;
  invoice_date: string;
  invoice_total_amount: string | number;
  total_trainer_fee: string | number;
  total_traqom_incentive: string | number;
  total_bilingual_incentive: string | number;
  total_learners_incentive: string | number;
  is_paynow: boolean;
  bank_account_number: string;
  bank_name: string;
  bank_recipient_name: string;
  paynow_mobile_no: string;
  paynow_recipient_name: string;
  paynow_uen: string;
  allowances: Allowance[];
}) => {
  const formData = new FormData();

  data.selected_courseruns.forEach((cr, index) => {
    formData.append('selected_courseruns[]', cr);
  });
  formData.append('invoice_no', data.invoice_no);
  formData.append('invoice_date', data.invoice_date);
  formData.append('invoice_total_amount', parseFloat(String(data.invoice_total_amount)).toFixed(2));
  formData.append('total_trainer_fee', parseFloat(String(data.total_trainer_fee)).toFixed(2));
  formData.append(
    'total_traqom_incentive',
    parseFloat(String(data.total_traqom_incentive)).toFixed(2)
  );
  formData.append(
    'total_bilingual_incentive',
    parseFloat(String(data.total_bilingual_incentive)).toFixed(2)
  );
  formData.append(
    'total_learners_incentive',
    parseFloat(String(data.total_learners_incentive)).toFixed(2)
  );
  formData.append('is_paynow', data.is_paynow ? 'true' : 'false');
  formData.append('bank_account_number', data.bank_account_number);
  formData.append('bank_name', data.bank_name);
  formData.append('bank_recipient_name', data.bank_recipient_name);
  formData.append('paynow_mobile_no', data.paynow_mobile_no);
  formData.append('paynow_recipient_name', data.paynow_recipient_name);
  formData.append('paynow_uen', data.paynow_uen);
  formData.append('allowances', JSON.stringify(data.allowances));

  // Append allowances fields and files
  data.allowances.forEach((allowance, index) => {
    formData.append(`allowances[${index}][allowance_title]`, allowance.allowance_title);
    formData.append(
      `allowances[${index}][allowance_amount]`,
      parseFloat(String(allowance.allowance_amount)).toFixed(2)
    );
    formData.append(`allowances[${index}][allowance_image]`, allowance.allowance_image); // File upload
  });

  return axios.post('/trainer-api/submitInvoice', formData, {
    headers: {
      'Content-Type': 'multipart/form-data', // Important for handling file uploads
    },
  });
};

export const printInvoice = (data: { invoice_id: string | number }) => {
  return axios.post('/trainer-api/printInvoice', data);
};
