import { RouterProvider } from 'react-router-dom';
import router from 'routes';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import { persistor, store } from './redux/store';
import { ModalsProvider } from '@mantine/modals';
import { PersistGate } from 'redux-persist/integration/react';
import { notifications } from 'notifications';
import { getErrorMessage } from 'services/getErrorMessage';
import { axios } from 'services/axios';
import 'styles/general.scss';
import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

Sentry.init({
  dsn: 'https://bde92056d3ab68cd47ec0625836a9e75@o4503969180024832.ingest.us.sentry.io/4507372880199680',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      retry: false,
      onSuccess: () => {
        notifications.success({
          message: 'Success',
        });
      },
      onError: (error) => {
        notifications.error({
          title: 'Error',
          message: getErrorMessage(error),
        });
      },
    },
    queries: { retry: false, refetchOnWindowFocus: false },
  },
});

axios.interceptors.request.use(
  function (config) {
    config.headers = Object.assign(config.headers, {
      Authorization: 'Bearer ' + store.getState()?.auth?.token,
    });
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <MantineProvider
            withGlobalStyles
            withNormalizeCSS
            theme={{
              fontFamily: "'Poppins', sans-serif",
              defaultGradient: { from: 'teal', to: 'blue', deg: 60 },
              breakpoints: {
                xs: '30em',
                sm: '48em',
                md: '64em',
                lg: '74em',
                xl: '90em',
              },
              colors: {
                brand: [
                  '#F3ECF5',
                  '#dec3fa',
                  '#cb96f2',
                  '#bb63e6',
                  '#9538d9',
                  '#7720c9',
                  '#000000',
                  '#8c4799',
                  '#5c0992',
                  '#49087f',
                ],
              },
              primaryColor: 'brand',
            }}
          >
            <ModalsProvider modalProps={{ keepMounted: false }}>
              <Notifications position="top-center" zIndex={1000} />
              <RouterProvider router={router} />
            </ModalsProvider>
          </MantineProvider>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
