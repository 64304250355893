import {
  Box,
  SimpleGrid,
  Stack,
  Title,
  Text,
  Center,
  Flex,
  Group,
  Paper,
  ActionIcon,
  Button,
  Loader,
  Image,
} from '@mantine/core';
import { IconFileInvoice } from '@tabler/icons-react';
import { ReactComponent as RibbonSvg } from 'assets/dashboard/bg vector01.svg';
import { ReactComponent as ReferralStatSvg } from 'assets/dashboard/icona_ref.svg';
import { ReactComponent as ReadyStatSvg } from 'assets/dashboard/icona_ready.svg';
import { ReactComponent as PaidStatSvg } from 'assets/dashboard/icon_paid.svg';
import { ReactComponent as LearnerStatSvg } from 'assets/dashboard/icon_learner.svg';
import { ReactComponent as RatingSvg } from 'assets/dashboard/icon_traqom.svg';
import { ReactComponent as LeadStatSvg } from 'assets/dashboard/icon_lead.svg';
import { ReactComponent as DownloadSvg } from 'assets/dashboard/icon_dl.svg';
import styles from './DashboarPage.module.scss';
import { useMutation, useQuery } from '@tanstack/react-query';
import { API } from 'services';
import { useEffect, useState } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { format_currency } from 'utils/format';
import { DataTable } from 'components/DataTable';
import { Calendar } from 'components/Calendar';
import { notifications } from 'notifications';
import { useLoginUser } from 'redux/selectors/useLoginUser';
import { ResetPasswordFirstTimeModal } from '../Profile/ResetPasswordFirstTimeModal';
import { WelcomeFloatingImage } from './WelcomeFloatingImage';
import { columnsDefsArray } from './columnsRenderer';
import { DataTableSortStatus } from 'mantine-datatable';
import ppImage from 'assets/inner/pp.png';
import loading from 'assets/report/loading.gif';

export function DashboardPage() {
  const { loginUser } = useLoginUser();
  const [resetFirstTimeModalOpen, resetFirstTimeModalHandler] = useDisclosure(false);
  const [uploadModalOpened, uploadModalHelper] = useDisclosure(false);
  const [selectedRecords, setSelectedRecords] = useState<any[]>([]);
  const [leadTableRefetchKey, setLeadTableRefetchKey] = useState(0);
  const [isLoading, setLoading] = useState(false);
  // function calendar(){
  //   return (
  //     <div className="App">
  //       <Calendar loginUser={loginUser} />
  //     </div>
  //   )
  // }
  const ratingStyle = {
    marginLeft: '20px',
  };
  const centerLine = {
    borderRight: '1px solid #afafaf',
    paddingRight: '40px',
    marginRight: '20px',
  };

  function greet() {
    const now = new Date();
    const currentHour = now.getHours();
    let greeting;

    if (currentHour >= 5 && currentHour < 12) {
      greeting = 'Good Morning';
    } else if (currentHour >= 12 && currentHour < 18) {
      greeting = 'Good Afternoon';
    } else {
      greeting = 'Good Evening';
    }
    return greeting;
  }

  useEffect(() => {
    if (loginUser) {
      if (loginUser.trainer_reset_password) {
        resetFirstTimeModalHandler.open();
      }
    }
  }, [loginUser]);

  useEffect(() => {
    // prevent horizontal scroll back and forward navigation
    // because our datatable require horizontal scroll alots
    const cached = document.body.style.overscrollBehaviorX;
    document.body.style.overscrollBehaviorX = 'none';
    return () => {
      document.body.style.overscrollBehaviorX = cached;
    };
  }, []);

  return (
    <Stack px={{ base: 0, md: 30 }}>
      {isLoading && (
        <Box className={'loading_box'}>
          <Image src={loading} width="100"></Image>
        </Box>
      )}
      <ResetPasswordFirstTimeModal
        opened={resetFirstTimeModalOpen}
        onClose={resetFirstTimeModalHandler.close}
      />

      <Box
        bg="#F0EEFC"
        sx={(theme) => ({
          borderRadius: theme.radius.md,
        })}
        style={{ overflow: 'hidden' }}
      >
        <SimpleGrid h="100%" cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
          <Flex h="100%" px={{ base: 15, md: 30 }} className=" flex_direction_column">
            {/* <Box pos={'absolute'} top={0} left={0} className={styles.stripe}>
              <RibbonSvg />
            </Box> */}

            <Flex py={20}>
              {loginUser.trainer_image_url ? (
                <Image
                  src={loginUser.trainer_image_url}
                  width="80"
                  className="profile_image sm_block_image"
                ></Image>
              ) : (
                <Image src={ppImage} width="80" className="profile_image sm_block_image"></Image>
              )}
              <Box px={{ base: 0, sm: 20 }}>
                <Title order={2}>
                  {greet()},
                  <Text display={'inline'} color="#8c4799" ml={4}>
                    {loginUser.trainer_name}
                  </Text>
                </Title>
                <Text size={'lg'}>The art of teaching is the art of assisting discovery.</Text>
              </Box>
            </Flex>
            <Flex my={20} className="rating">
              <Flex
                px={{
                  base: 0,
                  sm: 20,
                }}
                className="rating_box center_line flex_wrap flex_direction_row"
              >
                <Flex style={{ width: '100%' }} my={20}>
                  <Box style={{ width: 'calc(100% - 110px)' }}>
                    <Text fw={700} size={'lg'} color="#8c4799">
                      {loginUser.traqom_rating} /{' '}
                      <Text display={'inline'} fw={500} color="#8c4799">
                        5
                      </Text>
                    </Text>
                    <Text fw={500}>TRAQOM Rating</Text>
                  </Box>
                  <Flex style={{ width: '110px' }} className={'flex_justify_center'}>
                    <RatingSvg className="" style={{ width: '60px' }} />
                  </Flex>
                </Flex>

                <Flex style={{ width: '100%' }} my={5}>
                  <Text fw={500} style={{ width: 'calc(100% - 110px)' }}>
                    Latest Class Update
                  </Text>
                  <Text fw={700} style={{ width: '110px' }} align="center" color="#8c4799">
                    {loginUser.traqom_latest}
                  </Text>
                </Flex>

                <Flex style={{ width: '100%' }} my={5}>
                  <Text fw={500} style={{ width: 'calc(100% - 110px)' }}>
                    Total Classes
                  </Text>
                  <Text fw={700} style={{ width: '110px' }} align="center" color="#8c4799">
                    {loginUser.total_classes}
                  </Text>
                </Flex>

                <Flex style={{ width: '100%' }} my={5}>
                  <Text fw={500} style={{ width: 'calc(100% - 110px)' }}>
                    Classes Not Updated
                  </Text>
                  <Text fw={700} style={{ width: '110px' }} align="center" color="#8c4799">
                    {loginUser.total_classes_not_update}
                  </Text>
                </Flex>
              </Flex>

              <Flex
                px={{
                  base: 0,
                  sm: 20,
                }}
                className="rating_box flex_wrap flex_direction_row"
              >
                <Flex style={{ width: '100%' }} my={20}>
                  <Box style={{ width: 'calc(100% - 110px)' }}>
                    <Text fw={700} size={'lg'} color="#8c4799">
                      {loginUser.le_rating} /{' '}
                      <Text display={'inline'} fw={500} color="#8c4799">
                        6
                      </Text>
                    </Text>
                    <Text fw={500}>{"Learner's Evaluation"}</Text>
                  </Box>
                  <Flex style={{ width: '110px' }} className={'flex_justify_center'}>
                    <LearnerStatSvg className="" style={{ width: '60px' }} />
                  </Flex>
                </Flex>

                <Flex style={{ width: '100%' }} my={5}>
                  <Text fw={500} style={{ width: 'calc(100% - 110px)' }}>
                    Latest Class Update
                  </Text>
                  <Text fw={700} style={{ width: '110px' }} align="center" color="#8c4799">
                    {loginUser.le_dated_update}
                  </Text>
                </Flex>

                <Flex style={{ width: '100%' }} my={5}>
                  <Text fw={500} style={{ width: 'calc(100% - 110px)' }}>
                    Total Classes
                  </Text>
                  <Text fw={700} style={{ width: '110px' }} align="center" color="#8c4799">
                    {loginUser.le_total_classes}
                  </Text>
                </Flex>

                <Flex style={{ width: '100%' }} my={5}>
                  <Text fw={500} style={{ width: 'calc(100% - 110px)' }}>
                    Classes Not Updated
                  </Text>
                  <Text fw={700} style={{ width: '110px' }} align="center" color="#8c4799">
                    {loginUser.le_total_classes_not_update}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            {/* <Box className={styles.strip_container}>
              <Box className={styles.strip_background}>
                <RibbonSvg className={styles.strip_image} />
              </Box>
            </Box> */}
          </Flex>
          <WelcomeFloatingImage />
        </SimpleGrid>
      </Box>

      <div className="App">
        <Calendar loginUser={loginUser} setLoading={setLoading} />
      </div>
      {/* {Calendar()} */}
    </Stack>
  );
}
