import {
  Box,
  Button,
  Center,
  Container,
  Collapse,
  Flex,
  Stack,
  Text,
  Title,
  Modal,
  Image
} from '@mantine/core';
import { API } from 'services';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { useState } from 'react';
import { useDisclosure } from '@mantine/hooks';

import { useQuery } from '@tanstack/react-query';
import { DataTable } from 'components/DataTable';
import Swal from 'sweetalert2';
import moment from 'moment';

import { ReactComponent as CalSvg } from 'assets/inner/icon_cal.svg';
import { ReactComponent as BookSvg } from 'assets/inner/icon_book.svg';
import { ReactComponent as ClockSvg } from 'assets/inner/icon_clock.svg';
import { ReactComponent as LocateSvg } from 'assets/inner/icon_locate.svg';
import { ReactComponent as NameSvg } from 'assets/inner/name.svg';
import loading from 'assets/report/loading.gif';
// import { AcknowledgeModal } from '../Course/acknowledgeModal';

interface Event {
  course_id: number;
  courserun_id: number;
  course_title: string;
  course_ref: string;
  courserun_apicourserunid: string;
  courserun_bilingual: string;
  courserun_modeoftraining: string;
  traininglocation_title: string;
  venue_floor: string;
  venue_unit: string;
  venue_bulding: string;
  venue_postalcode: string;
  venue_room: string;
  courserun_course_from: string;
  courserun_course_to: string;
  session: string;
  csession_start_date: string;
  csession_start_time: string;
  csession_end_date: string;
  csession_end_time: string;
  background_color: string;
  course_color: string;
  traininglocation_title_short: string;
  overlay: string;
}

const convertCourseRunToEvents = (unassign_course:any) => {
  return unassign_course.course_available.map((item:any) => ({
    title: item.course_title,
    start: item.csession_start_date+' '+item.csession_start_time,
    end: item.csession_end_date+' '+item.csession_end_time,
    color: '#fff',
    // color: item.background_color,
    // classNames: ['calendar_event'],
    // url: "/course?course="+item.course_id+"&course_run="+item.courserun_id
    extendedProps: {
      courseId: item.course_id,
      courseRunId: item.courserun_id,
      customHtml: `
        <div style="display: flex; align-items: center; white-space: normal; padding:2px; background-color: ${item.background_color}" class="${item.overlay}">
          <span
            style="
              width: 10px;
              height: 10px;
              background-color: ${item.course_color};
              display: inline-block;
              margin-right: 5px;
              border-radius: 50%;
            "
          ></span>
          <span style="overflow:hidden;height:20px;width: calc(100% - 15px);"><b>${item.traininglocation_title_short} |</b> ${item.course_title}</span>
        </div>
      `
    },
  }));
};

const renderEventContent = (eventInfo:any) => {
  const { customHtml } = eventInfo.event.extendedProps;
  return (
    <div dangerouslySetInnerHTML={{ __html: customHtml }}></div>
  );
};

const formatSessionDateRange = (start_date:any, end_date:any) => {
  // Parse start and end dates using moment
  const startDate = moment(start_date);
  const endDate = moment(end_date);

  // Format start date like "06 May, Mon"
  const formattedStartDate = startDate.format('DD MMM, ddd');

  // Format end date like "08 May, Web"
  const formattedEndDate = endDate.format('DD MMM, ddd');

  // Combine formatted dates with a dash
  const formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`;

  return formattedDateRange;
};

const CourseItem = ({ item, index, openModal }: { item: any, index: number, openModal: (event: any) => void }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleButtonClick = () => {
    const event = {
      event: {
        extendedProps: {
          courseId: item.course_id,
          courseRunId: item.courserun_id,
        }
      }
    };
    openModal(event);
  };

  return (
    item.course_session ? (
      <Box bg={"#f2f2f2"} key={index} my={5} p={10}>
        <Flex onClick={() => setIsOpen(!isOpen)} className={'unavailable_course_box'}>
          <Box className={'unavailable_course_detail'}>
            <Flex className={'flex_align_center'}>
              <CalSvg style={{ marginRight: "5px" }} />
              <Text>
                <span
                  style={{
                    width: '10px',
                    height: '10px',
                    backgroundColor: item.course_color,
                    display: 'inline-block',
                    marginRight: '5px',
                    borderRadius: '10px'
                  }}
                ></span>
                {formatSessionDateRange(item.csession_start_date, item.csession_end_date)}
              </Text>
              <Text className={'collapse_icon'}>{isOpen ? '-' : '+'}</Text>
            </Flex>
          </Box>
        </Flex>

        <Collapse in={isOpen} transitionDuration={1000} transitionTimingFunction="linear">
          <Box mx={5} className={'unavailable_course_box'}>
            <Box className={'unavailable_course_detail'}>
              <Flex className={'flex_align_center'}><ClockSvg style={{ marginRight: "5px" }} /><Text dangerouslySetInnerHTML={{ __html: item.course_session }}></Text></Flex>
              <Flex className={'flex_align_center'}><BookSvg style={{ marginRight: "5px" }} /><Text>{item.course_ref}</Text></Flex>
              <Flex className={'flex_align_center'}><NameSvg style={{ marginRight: "5px" }} /><Text>{item.course_title}</Text></Flex>
              <Flex className={'flex_align_center'}><LocateSvg style={{ marginRight: "5px" }} /><Text>{item.traininglocation_title}</Text></Flex>
              <Flex className={'flex_justify_center'} justify="space-between">
                <Button
                  onClick={handleButtonClick}
                >
                  Submit Interest
                </Button>
              </Flex>
            </Box>
          </Box>
        </Collapse>
      </Box>
    ) : null
  );
};

const CourseList = ({ unassign_course, openModal }: { unassign_course: any, openModal: (event: any) => void }) => {
  if (!unassign_course || !unassign_course.course_available) {
    return <div>Loading...</div>;
  }

  return unassign_course.course_available.map((item: any, index: number) => (
    <CourseItem key={index} item={item} index={index} openModal={openModal} />
  ));
};

export function CourseRunPage(props: any) {
  const [date_start, setDateStart] = useState(moment().subtract(1, 'months').format('YYYY-MM-DD'));
  const [date_end, setDateEnd] = useState(moment().add(1, 'months').format('YYYY-MM-DD'));
  const [course_detail, setCourseDetail] = useState<any>(<Box></Box>);
  const [modalIsOpen, setIsOpen] = useState(false);
  // const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
  const [isLoading, setLoading] = useState(false);
  // const [selectedLocation, setSelectedLocation] = useState(0);
  // const [selectedCourse, setSelectedCourse] = useState(0);
  const [selectedLocations, setSelectedLocations] = useState<number[]>([]);
  const [selectedCourses, setSelectedCourses] = useState<number[]>([]);

  const unassign_course = useQuery(['unassign_course', {
    date_start: date_start ? date_start : moment().subtract(1, 'months').format('YYYY-MM-DD'),
    date_end: date_end ? date_end : moment().add(1, 'months').format('YYYY-MM-DD'),
    location_id: selectedLocations,
    course_id: selectedCourses
  }], () => {
    setLoading(true);
    setCourseDetail("");
    return API.getUnassignCourse({
      date_start: date_start ? date_start : moment().subtract(1, 'months').format('YYYY-MM-DD'),
      date_end: date_end ? date_end : moment().add(1, 'months').format('YYYY-MM-DD'),
      location_id: selectedLocations,
      course_id: selectedCourses
    })
      .then((res) => res.data);
  },
  {
    onSuccess: () => {
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    },
    onSettled: () => {
      setLoading(false);
    }
  });

  const course_color = useQuery(['course_color'], () => { 
    return API.getCourseColor()
      .then((res) => res.data); 
  });

  const training_location = useQuery(['training_location'], () => { 
    return API.getTrainingLocation()
      .then((res) => res.data); 
  });
  
  const events = unassign_course.data ? convertCourseRunToEvents(unassign_course.data) : [];

  const handleDatesSet = (info: any) => {
    const inputDate = new Date(info.start);
    setDateStart(formatDate(inputDate));
    const inputEndDate = new Date(info.end);
    setDateEnd(formatDate(inputEndDate));
  };

  const formatDate = (inputDate: any) => {
    return moment(inputDate).format('YYYY-MM-DD');
  };

  const openModal = async (event: any) => {
    setLoading(true);
    const { courseId, courseRunId } = event.event.extendedProps;

    try {
      const courseDetail = await API.getCourseDetail({
        course_id: courseId,
        courserun_id: courseRunId,
      }).then((res) => res.data);

      setSelectedEvent({
        ...courseDetail,
      });

      setLoading(false);
      setIsOpen(true);
    } catch (error) {
      console.error("Failed to fetch course details:", error);
    }
  };

  const course_lists = unassign_course.data ? <CourseList unassign_course={unassign_course.data} openModal={openModal} /> : null;
  const closeModal = () => {
    setIsOpen(false);
    setSelectedEvent(null);
  };
  
  // const handleCourseClick = (id:any) => {
  //   setSelectedCourse(id);
  // };
  const handleCourseClick = (id: number) => {
    if (id === 0) {
        // If "All" is clicked, clear all selections
        setSelectedCourses([]);
    } else {
        setSelectedCourses(prevSelectedCourses => {
            if (prevSelectedCourses.includes(id)) {
                // If the course is already selected, remove it from the array
                return prevSelectedCourses.filter(courseId => courseId !== id);
            } else {
                // If the course is not selected, add it to the array
                return [...prevSelectedCourses, id];
            }
        });
    }
  };

  // const handleLocationClick = (id:any) => {
  //   setSelectedLocation(id);
  // };
  const handleLocationClick = (id: number) => {
    setSelectedLocations((prevSelected) => {
      if (id === 0) {
        return []; // If "All" is clicked, reset the selection
      }
      
      if (prevSelected.includes(id)) {
        // Remove the location if it's already selected
        return prevSelected.filter((locationId) => locationId !== id);
      } else {
        // Add the location if it's not already selected
        return [...prevSelected, id];
      }
    });
  };

  const handleSubmitInterest = async (courserun_id:any) => {
    setLoading(true);
    // Show confirmation dialog
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to submit your interest for this course?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, submit it!',
      cancelButtonText: 'No, cancel!',
    });
  
    // If user confirmed, proceed with API call
    if (result.isConfirmed) {
      API.submitInterest({
        courseRunId: courserun_id,
      }).then((response) => {
        setLoading(false);
        if(response.data.status){
          Swal.fire({
            title: 'Submitted!',
            text: 'Your interest has been submitted.',
            icon: 'success',
            confirmButtonText: 'OK',
          }).then((result) => {
            if (result.isConfirmed) {
              closeModal();
            }
          });
        }else{
          Swal.fire({
            title: 'Fail!',
            text: response.data.message,
            icon: 'warning',
            confirmButtonText: 'OK',
          }).then((result) => {
            if (result.isConfirmed) {
              closeModal();
            }
          });
        }
      }).catch((error) => {
        Swal.fire({
          title: 'Error!',
          text: 'There was an error submitting your interest. Please try again.',
          icon: 'error',
          confirmButtonText: 'OK',
        }).then((result) => {
          if (result.isConfirmed) {
            closeModal();
          }
        });
      });
    }else{
      setLoading(false);
    }
  };

  return (
    <Stack px={{ base: 0, md: 30 }}>
      {isLoading && 
        <Box className={"loading_box"}><Image src={loading} width="100"></Image></Box>
      }
      <Flex className={'flex_wrap'}>
        <Box p={10} className={'w70 sm_w100 sm_order_2'}>
          <Box bg="#fff" p={20}>
            <Title order={2} mb={'lg'}>
              Calendar View - All Classes
            </Title>
            <hr></hr>

            {training_location.data && (
              <Box>
                <Button 
                  m={5} 
                  bg={selectedLocations.length === 0 ? "#f3ecf5" : "white"}  
                  variant="outline" 
                  color="black"
                  onClick={() => handleLocationClick(0)}
                >
                  All
                </Button>
                
                {training_location.data.training_locations.map((item: any, index: any) => (
                  <Button 
                    key={item.key}
                    m={5} 
                    bg={selectedLocations.includes(item.traininglocation_id) ? "#f3ecf5" : "white"} 
                    variant="outline" 
                    color="black"
                    onClick={() => handleLocationClick(item.traininglocation_id)} 
                  >
                    {item.traininglocation_title}
                  </Button>
                ))}
                
                <Button 
                  m={5} 
                  bg={selectedLocations.includes(1000) ? "#f3ecf5" : "white"} 
                  variant="outline" 
                  color="black"
                  onClick={() => handleLocationClick(1000)}
                >
                  Online Class
                </Button>
              </Box>
            )}
              

            <Box className="new_course_calendar">
              <FullCalendar
                plugins={[dayGridPlugin]}
                initialView="dayGridMonth"
                headerToolbar={{
                  start: "",
                  center: "title",
                  end: "prev,next today"
                }}
                eventClick={openModal}
                events={events}
                datesSet={handleDatesSet}
                eventContent={renderEventContent}
                slotDuration={'00:30:00'}
                slotLabelInterval={'01:00'}
                eventOverlap={false}
                height="auto"
                dayMaxEventRows={true}
                moreLinkClick="popover"
              />
            </Box>
            <Flex py={10}>
              <Text bg="#d0ffd1" w={200} p={2} m={5} fz="sm" ta="center">Your Classes</Text>
              <Text bg="#d0e4ff" w={200} p={2} m={5} fz="sm" ta="center">Available Classes</Text>
              <Text bg="#ff0000" w={200} p={2} m={5} fz="sm" ta="center">Conflict Classes</Text>
            </Flex>
          </Box>
        </Box>

        <Box p={10} className={'w30 sm_w100 sm_order_1'}>
          
        <Box bg="#fff" p={20}>
          <Title order={2} mb={'lg'}>
              Filter Classes
              <Button 
                  style={{float:'right'}}
                  h={30}
                  bg={selectedCourses.length === 0 ? "#f3ecf5" : "white"}  
                  variant="outline" 
                  color="black"
                  onClick={() => handleCourseClick(0)}
              >
                  All
              </Button>
          </Title>
          <hr />
          <Box className="course_color_box">
              {course_color.data && (
                  <Box>
                      {course_color.data.courses.map((item: any, index: any) => (
                          <Flex 
                              key={item.key} 
                              bg={selectedCourses.includes(item.course_id) ? "#f3ecf5" : "white"} 
                              my={5} 
                              style={{ cursor: "pointer" }} 
                              onClick={() => handleCourseClick(item.course_id)} 
                          >
                              <span
                                  style={{
                                      width: '10px',
                                      height: '10px',
                                      marginTop: '7px',
                                      backgroundColor: item.course_color,
                                      display: 'inline-block',
                                      marginRight: '5px',
                                      borderRadius: '10px'
                                  }}
                              ></span>
                              <Box style={{ width: "calc(100% - 15px)" }}>
                                  {item.course_title}
                              </Box>
                          </Flex>
                      ))}
                  </Box>
              )}
          </Box>
        </Box>

          <Box bg="#fff" p={20} my={20}>
            <Title order={2} mb={'lg'}>
              New Class Listing
            </Title>
            <hr></hr>
            <Box className="course_listing_box">
              {course_lists}
            </Box>
          </Box>
        </Box>
      </Flex>

      {unassign_course &&
        <DataTable
          className={'learner_table hide'}
          records={unassign_course.data ? unassign_course.data.course_available : []}
          columns={[
            { accessor: 'sequence_number', sortable: false, 'title': '#' },
            { accessor: 'course_title', sortable: false, 'title': 'Course Title' },
            { accessor: 'traininglocation_title', sortable: false, 'title': 'Location' },
            { accessor: 'csession_start_date', sortable: false, 'title': 'Start Date' },
            { accessor: 'csession_end_date', sortable: false, 'title': 'End Date' },
            { accessor: 'csession_start_time', sortable: false, 'title': 'Start Time' },
            { accessor: 'csession_end_time', sortable: false, 'title': 'End Time' },
            {
              accessor: 'submit_interest',
              sortable: false,
              title: 'Interest',
              render: (record: any) => (
                <Button
                  onClick={() => openModal({ event: { extendedProps: { courseId: record.course_id, courseRunId: record.courserun_id } } })}
                >
                  Submit Interest
                </Button>
              )
            }
          ]}
        />
      }

      <Modal {...props} opened={modalIsOpen} size={'xl'} closeOnClickOutside={false} keepMounted={false} withCloseButton={false}>
        <Center>
          <Container w="100%" my={30}>
            <Title size={'md'} ta="center" my={20}>
              Please confirm the course details before submit your interest.
            </Title>
            {selectedEvent && (
              <Box>
                <Title size={'md'} ta="center"  color="#8c4799">{ selectedEvent.course_title }</Title>
                <Flex className="course_detail flex_wrap" my={30}>        
                  <Box>
                    <BookSvg />
                    <Text fw={700} ml={5} display={'inline'}>Course ID</Text>
                    <Text>{ selectedEvent.course_ref }</Text>
                    <BookSvg />
                    <Text fw={700} ml={5} display={'inline'}>Course TPG ID</Text>
                    <Text>{ selectedEvent.courserun_apicourserunid }</Text>
                    <BookSvg />
                    <Text fw={700} ml={5} display={'inline'}>Bilingual Class</Text>
                    <Text>{ selectedEvent.courserun_bilingual == "1" ? "Yes" : "No" }</Text>
                  </Box>
                  <Box>
                    <LocateSvg />
                    <Text fw={700} ml={5} display={'inline'}>Location</Text>
                    {selectedEvent.courserun_modeoftraining === "Face-to-Face Classroom" ? (
                      <Box>
                        <Text dangerouslySetInnerHTML={{ __html: "<b>" + selectedEvent.traininglocation_title + "</b><br> " 
                        + "#" + selectedEvent.venue_floor + '-' + selectedEvent.venue_unit + ', ' 
                        + selectedEvent.venue_bulding + ' <br>Singapore ' + selectedEvent.venue_postalcode }}>
                        </Text>
                        <Text dangerouslySetInnerHTML={{ __html: "<b>Class Room</b><br> " 
                        + selectedEvent.venue_room }}></Text>
                      </Box>
                    ) : (
                      <Text>Online Class</Text>
                    )}
                  </Box>
                  <Box>
                    <CalSvg />
                    <Text fw={700} ml={5} display={'inline'}>Course Dates</Text>
                    <Text>
                      { new Date(selectedEvent.courserun_course_from).toLocaleDateString('en-GB', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                        weekday: 'short',
                      }) } - 
                      <br></br>
                      { new Date(selectedEvent.courserun_course_to).toLocaleDateString('en-GB', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                        weekday: 'short',
                      }) } 
                    </Text>
                  </Box>
                  <Box>
                    <ClockSvg />
                    <Text fw={700} ml={5} display={'inline'}>Course Timings</Text>
                    <Text dangerouslySetInnerHTML={{ __html: selectedEvent.session }}></Text>
                  </Box>
                </Flex>
                  <Flex className="flex_justify_center" style={{width:'100%'}} my={10}>
                    <Button onClick={() => handleSubmitInterest(selectedEvent.courserun_id)} m={5}>
                      Submit Interest
                    </Button>
                    <Button onClick={() => closeModal()} m={5}  className="cancel_button">
                      Cancel
                    </Button>
                  </Flex>
              </Box>
            )}
          </Container>
        </Center>
      </Modal>

    </Stack>
  );
}
